<template>
  <div>
     <b-modal @hidden="resetModal" ref="modal-create-user" size="lg" centered hide-footer  :no-close-on-backdrop="true" content-class="overflow-hidden">
        <template #modal-title>
           <h5 class="text-info mb-0 text-dark">
              <i class="uil uil-user me-1 text-primary"></i> Create a User Account
           </h5>
        </template>
        <div class="row align-items-top">
              <div class="mb-3 col-12 col-md-12">
                 <label>Username <span v-if="updateData.preview_username && formData.username" class="d-block fw-normal font-size-12 text-muted">Login Username is <span v-if="updateData.preview_username && formData.username" class="font-size-13 fw-medium text-success">{{ formData.username }}@{{updateData.preview_username}}</span></span></label>
                 <div class="input-group">
                 <input
                    v-model.trim="formData.username"
                    @input="formData.username = $event.target.value.toLowerCase()"
                    type="text"
                    class="form-control"
                    placeholder="Username"
                    name="username"
                    :class="{
                    'is-invalid':
                    modalSubmit && $v.formData.username.$error,
                    }"
                    />
                    <span class="input-group-text" v-if="updateData.account_username">@{{updateData.preview_username}}</span>
                </div>
                <div
                    v-if="modalSubmit && $v.formData.username.$error"
                    class="invalid-feedback d-block"
                    >
                    <span v-if="!$v.formData.username.required"
                       >Username is required.</span
                       >
                    <span v-else-if="!$v.formData.username.validUsername"
                       >Username must contain only lowercase alphabets and numbers.</span
                       >
                 </div>
              
              </div>
              <div class="mb-3 col-12 col-md-12">
                 <label>Name</label>
                 <input
                    v-model.trim="formData.name"
                    type="text"
                    class="form-control"
                    placeholder="Name"
                    name="name"
                    :class="{
                    'is-invalid':
                    modalSubmit && $v.formData.name.$error,
                    }"
                    />
                 <div
                    v-if="modalSubmit && $v.formData.name.$error"
                    class="invalid-feedback"
                    >
                    <span v-if="!$v.formData.name.required"
                       >Name is required.</span
                       >
                 </div>
              </div>
              
              <div class="mb-3 col-12 col-md-12">
                 <label>Password</label>
                 <div class="position-relative">
                    <input
                       v-model="formData.password"
                       :type="passwordFieldType"
                       name="password"
                       class="form-control"
                       :class="{
                       'is-invalid':
                       modalSubmit && $v.formData.password.$error,
                       }"
                       placeholder="Password"
                       />
                    <div
                       v-if="modalSubmit && $v.formData.password.$error"
                       class="invalid-feedback"
                       >
                       <span v-if="!$v.formData.password.required"
                          >Password is required.</span
                          >
                       <span v-if="!$v.formData.password.minLength"
                          >Password must be at least 8 characters.</span
                          >
                       <span v-else-if="!$v.formData.password.alpha">
                       Password must be at least 8 characters and contain at least one number, as well as both lowercase and uppercase letters, along with special characters (e.g., HengHeng357#@, Admin12#@, etc.).</span
                          >
                    </div>
                    <div
                       id="eye"
                       class="user-select-none"
                       @click="switchVisibility()"
                       >
                       <i
                          :class="classicon"
                          id="eyetype"
                          aria-hidden="true"
                          ></i>
                    </div>
                 </div>
              </div>
              <div class="mb-3 col-12 col-md-12">
                 <label>Confirm Password</label>
                 <div class="position-relative">
                    <input
                       v-model="formData.confirmPassword"
                       :type="passwordFieldType2"
                       name="confirmPassword"
                       class="form-control"
                       :class="{
                       'is-invalid':
                       modalSubmit && $v.formData.confirmPassword.$error,
                       }"
                       placeholder="Confirm Password"
                       />
                    <div
                       v-if="
                       modalSubmit && $v.formData.confirmPassword.$error
                       "
                       class="invalid-feedback"
                       >
                       <span v-if="!$v.formData.confirmPassword.required"
                          >Confirm password is required.</span
                          >
                       <span
                          v-else-if="
                          !$v.formData.confirmPassword.sameAsPassword
                          "
                          >Confirm password does not match the new
                       password.</span
                          >
                    </div>
                    <div
                       id="eye2"
                       class="user-select-none"
                       @click="switchVisibility2()"
                       >
                       <i
                          :class="classicon2"
                          id="eyetype2"
                          aria-hidden="true"
                          ></i>
                    </div>
                 </div>
              </div>
              
          <p class="text-muted font-size-12"><i class="uil uil-exclamation-triangle font-size-15 text-warning me-2"></i> Password is Case Sensitive. </p>
            <div class="col-12">
            <label class="mb-2">Permissions</label>
            <table class="table align-middle mb-0">
              <thead>
                <tr>
                  <th scope="col" class="fw-medium">Modules</th>
                  <th scope="col" class="fw-medium text-center">View/Access</th>
                  <th scope="col" class="fw-medium text-center">Create/Modify</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(value, index) in formData.permissions" :key="index">
                  <td class="text-capitalize">{{value.name.replaceAll('_',' ')}}</td>
                  <td class="text-capitalize text-center">
                    <div class="form-check form-switch d-inline-block mb-0">
                      <input class="form-check-input" type="checkbox" role="switch" @change="moduleChecked(value)" v-model="value.view_permission" true-value="1" false-value="0">
                    </div>
                  </td>
                  <td class="text-center">
                    <div class="form-check form-switch d-inline-block mb-0">
                      <input class="form-check-input" type="checkbox" role="switch" :disabled="value.view_permission==0" v-model="value.create_modify_permission" true-value="1" false-value="0">
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              v-if="modalSubmit && $v.formData.permissions.$error "
              class="invalid-feedback d-block"
              >
              <span v-if="!$v.formData.permissions.required"
                >Permissions is required.</span
                >
                <span v-if="!$v.formData.permissions.minLength"
                  >Min. must enable one permissions.</span
                  >
                
          </div>
          </div>
            
           <div class="col-12">
              <hr>
              <div class="d-grid gap-2 d-md-block text-end">
                 <button type="button" class="order-1 btn fw-medium btn-secondary me-md-2" @click="$refs['modal-create-user'].hide()">Cancel</button>
                 <button type="button" class="btn fw-medium btn-info" @click="addUserRequest" :disabled="modalLoading || loading">
                 <span v-if="modalLoading"> Creating...</span>
                 <span v-else> Create </span>
                 <span class="spinner-border spinner-border-sm ms-2" v-if="modalLoading" role="status" aria-hidden="true"></span>
                 </button>
              </div>
           </div>
        </div>
     </b-modal>
     <b-modal @hidden="resetModal" ref="modal-edit-user" size="lg" centered hide-footer  :no-close-on-backdrop="true" content-class="overflow-hidden">
        <template #modal-title>
           <h5 class="text-info mb-0 text-dark">
              <i class="uil uil-user me-1 text-primary"></i> Edit User Account
           </h5>
        </template>
        <div class="row align-items-top">
          <div class="mb-3 col-12 col-md-12">
              <label>Username <span v-if="updateData.account_username && formEditData.username" class="d-block fw-normal font-size-12 text-muted">Login Username is <span v-if="updateData.account_username && formEditData.username" class="font-size-13 fw-medium text-success">{{ formEditData.username }}@{{updateData.account_username}}</span></span></label>
              <div><span class="badge bg-soft-info font-size-14">{{ updateData.username || '-' }}</span></div>
          
          </div>
          <div class="mb-3 col-12 col-md-12">
              <label>Name</label>
              <input
                v-model.trim="formEditData.name"
                type="text"
                class="form-control"
                placeholder="Name"
                name="name"
                :class="{
                'is-invalid':
                modalSubmit && $v.formEditData.name.$error,
                }"
                />
              <div
                v-if="modalSubmit && $v.formEditData.name.$error"
                class="invalid-feedback"
                >
                <span v-if="!$v.formEditData.name.required"
                    >Name is required.</span
                    >
              </div>
          </div>
          <div class="mb-3 col-12 col-md-12">
              <label>Password</label>
              <div> <button class="btn btn-info px-2 py-1 font-size-15 rounded" @click="showResetPasswordModal()">
                  <i class="uil uil-lock-alt"></i> Reset Password</button></div>
          </div>
          <div class="mb-3 col-12">
            <div class="align-items-center">
              <label class="mb-1 me-2">Account Status</label>
              <div>
                <div class="form-check form-switch form-check-custom ms-1 mb-0 font-size-14">
                  <input class="form-check-input" type="checkbox" role="switch" v-model="formEditData.status" true-value="available" false-value="unavailable" :disabled="modalLoading">
                </div>
              </div>
            </div>
          </div>
          <div class="mb-3 col-12">
            <div class="align-items-center">
              <label class="mb-1 me-2">Login Block
                <span v-if="formEditData.login_block==1" class="ms-1 badge bg-soft-danger rounded-pill font-size-12">Blocked</span>
              </label>
              <div>
                <div class="form-check form-switch form-check-custom ms-1 mb-0 font-size-14">
                  <input class="form-check-input" type="checkbox" role="switch" v-model="formEditData.login_block" true-value="1" false-value="0" :disabled="modalLoading">
                </div>
              </div>
            </div>
          </div>
            
          <div class="col-12">
            <label class="mb-2">Permissions</label>
            <table class="table align-middle mb-0">
              <thead>
                <tr>
                  <th scope="col" class="fw-medium">Modules</th>
                  <th scope="col" class="fw-medium text-center">View/Access</th>
                  <th scope="col" class="fw-medium text-center">Create/Modify</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(value, index) in formEditData.permissions" :key="index">
                  <td class="text-capitalize">{{value.name.replaceAll('_',' ')}}</td>
                  <td class="text-capitalize text-center">
                    <div class="form-check form-switch d-inline-block mb-0">
                      <input class="form-check-input" type="checkbox" role="switch" @change="moduleChecked(value)" v-model="value.view_permission" true-value="1" false-value="0">
                    </div>
                  </td>
                  <td class="text-center">
                    <div class="form-check form-switch d-inline-block mb-0">
                      <input class="form-check-input" type="checkbox" role="switch" :disabled="value.view_permission==0" v-model="value.create_modify_permission" true-value="1" false-value="0">
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              v-if="modalSubmit && $v.formEditData.permissions.$error "
              class="invalid-feedback d-block"
              >
              <span v-if="!$v.formEditData.permissions.required"
                >Permissions is required.</span
                >
                <span v-if="!$v.formData.permissions.minLength"
                  >Min. must enable one permissions.</span
                  >
                >
          </div>
          </div>
            
           <div class="col-12">
              <hr>
              <div class="d-grid gap-2 d-md-block text-end">
                 <button type="button" class="order-1 btn fw-medium btn-secondary me-md-2" @click="$refs['modal-edit-user'].hide()">Cancel</button>
                 <button type="button" class="btn fw-medium btn-info" @click="updateUserRequest" :disabled="modalLoading || loading">
                 <span v-if="modalLoading"> Updating...</span>
                 <span v-else> Update </span>
                 <span class="spinner-border spinner-border-sm ms-2" v-if="modalLoading" role="status" aria-hidden="true"></span>
                 </button>
              </div>
           </div>
        </div>
     </b-modal>
     <b-modal @hidden="resetModal2" ref="modal-reset-password" size="lg" centered hide-footer  :no-close-on-backdrop="true" content-class="overflow-hidden">
        <template #modal-title>
           <h5 class="text-info mb-0 text-dark">
              <i class="uil uil-user me-1 text-primary"></i> Reset Password
           </h5>
        </template>
        <div class="row align-items-top">
          <div class="mb-3 col-12 col-md-12">
              <label>Username <span v-if="updateData.account_username && formEditData.username" class="d-block fw-normal font-size-12 text-muted">Login Username is <span v-if="updateData.account_username && formEditData.username" class="font-size-13 fw-medium text-success">{{ formEditData.username }}:{{updateData.account_username}}</span></span></label>
              <div><span class="badge bg-soft-info font-size-14">{{ updateData.username || '-' }}</span></div>
          
          </div>
          <div class="mb-3 col-12 col-md-12">
                 <label>Password</label>
                 <div class="position-relative">
                    <input
                       v-model="resetPassword.password"
                       :type="passwordFieldType"
                       name="password"
                       class="form-control"
                       :class="{
                       'is-invalid':
                       modalSubmit && $v.resetPassword.password.$error,
                       }"
                       placeholder="Password"
                       />
                    <div
                       v-if="modalSubmit && $v.resetPassword.password.$error"
                       class="invalid-feedback"
                       >
                       <span v-if="!$v.resetPassword.password.required"
                          >Password is required.</span
                          >
                       <span v-if="!$v.resetPassword.password.minLength"
                          >Password must be at least 8 characters.</span
                          >
                       <span v-else-if="!$v.resetPassword.password.alpha">
                       Password must be at least 8 characters and contain at least one number, as well as both lowercase and uppercase letters, along with special characters (e.g., HengHeng357#@, Admin12#@, etc.).</span
                          >
                    </div>
                    <div
                       id="eye"
                       class="user-select-none"
                       @click="switchVisibility()"
                       >
                       <i
                          :class="classicon"
                          id="eyetype"
                          aria-hidden="true"
                          ></i>
                    </div>
                 </div>
              </div>
              <div class="mb-3 col-12 col-md-12">
                 <label>Confirm Password</label>
                 <div class="position-relative">
                    <input
                       v-model="resetPassword.confirmPassword"
                       :type="passwordFieldType2"
                       name="confirmPassword"
                       class="form-control"
                       :class="{
                       'is-invalid':
                       modalSubmit && $v.resetPassword.confirmPassword.$error,
                       }"
                       placeholder="Confirm Password"
                       />
                    <div
                       v-if="
                       modalSubmit && $v.resetPassword.confirmPassword.$error
                       "
                       class="invalid-feedback"
                       >
                       <span v-if="!$v.resetPassword.confirmPassword.required"
                          >Confirm password is required.</span
                          >
                       <span
                          v-else-if="
                          !$v.resetPassword.confirmPassword.sameAsPassword
                          "
                          >Confirm password does not match the new
                       password.</span
                          >
                    </div>
                    <div
                       id="eye2"
                       class="user-select-none"
                       @click="switchVisibility2()"
                       >
                       <i
                          :class="classicon2"
                          id="eyetype2"
                          aria-hidden="true"
                          ></i>
                    </div>
                 </div>
              </div>
              
          <p class="text-muted font-size-12"><i class="uil uil-exclamation-triangle font-size-15 text-warning me-2"></i> Password is Case Sensitive. </p>
            
           <div class="col-12">
              <hr>
              <div class="d-grid gap-2 d-md-block text-end">
                 <button type="button" class="order-1 btn fw-medium btn-secondary me-md-2" @click="$refs['modal-reset-password'].hide()">Cancel</button>
                 <button type="button" class="btn fw-medium btn-info" @click="resetPasswordRequest" :disabled="modalLoading || loading">
                 <span v-if="modalLoading"> Reseting...</span>
                 <span v-else> Reset Password </span>
                 <span class="spinner-border spinner-border-sm ms-2" v-if="modalLoading" role="status" aria-hidden="true"></span>
                 </button>
              </div>
           </div>
        </div>
     </b-modal>
     <b-modal @hidden="resetModal" ref="modal-modules" size="lg" centered hide-footer content-class="overflow-hidden">
        <template #modal-title>
           <h5 class="text-info mb-0 text-dark">
              <i class="uil uil-lock-access me-1 text-primary"></i> {{ updateData.name }}'s Permissions
           </h5>
        </template>
        <div class="row align-items-center">
          <div class="col-12">
            <table class="table align-middle mb-0">
              <thead>
                <tr>
                  <th scope="col" class="fw-medium">Modules</th>
                  <th scope="col" class="fw-medium text-center">View/Access</th>
                  <th scope="col" class="fw-medium text-center">Create/Modify</th>
                </tr>
              </thead>
              <tbody class="table-group-divider">
                <tr v-for="(value, index) in updateData.modules" :key="index">
                  <td class="text-capitalize">{{value.name.replaceAll('_',' ')}}</td>
                  <td class="text-center">
                    <span v-if="value.view_permission==0" class="text-danger fw-medium">
                      <i class="uil uil-times-circle me-1 font-size-18"></i>
                      </span>
                     <span v-else-if="value.view_permission==1" class="text-success fw-medium">
                      <i class="uil uil-check-circle me-1 font-size-18"></i>
                      </span>
                  </td>
                  <td class="text-center">
                    <span v-if="value.create_modify_permission==0" class="text-danger fw-medium">
                      <i class="uil uil-times-circle me-1 font-size-18"></i>
                      </span>
                     <span v-else-if="value.create_modify_permission==1" class="text-success fw-medium">
                      <i class="uil uil-check-circle me-1 font-size-18"></i>
                      </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
         
          <div class="col-12">
              <hr>
              <div class="d-grid gap-2 d-md-block text-end">
                <button type="button" class="order-1 btn fw-medium btn-secondary me-md-2" @click="$refs['modal-modules'].hide()">Cancel</button>
              </div>
          </div>
        </div>
     </b-modal>
     <Common ref="commonFunc" />
  </div>
</template>

<script>
  import appConfig from "@/app.config";
  import axios from "axios";
  import Swal from "sweetalert2";
  import Common from "@/components/common";
  import { 
    required, 
    minLength, 
    sameAs, 
    helpers, 
    //url
  } from "vuelidate/lib/validators";
  const alpha = helpers.regex("alpha", /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/);
  const validUsername = (value) => {
    const regex = /^[a-z0-9]+$/;
    return regex.test(value);
  };

  /**
   * Starter page
   */
   const MerchantModules = [
    {
    "name": "payin",
    "view_permission":"1",
    "create_modify_permission":"1"
    },
    {
    "name": "payout",
    "view_permission":"1",
    "create_modify_permission":"1"
    },
    {
    "name": "settlements",
    "view_permission":"1",
    "create_modify_permission":"1"
    },
    {
    "name": "my_contracts",
    "view_permission":"1",
    "create_modify_permission":"1"
    },
    {
    "name": "reports",
    "view_permission":"1",
    "create_modify_permission":"1"
    },
    {
    "name": "technical",
    "view_permission":"1",
    "create_modify_permission":"1"
    },
    {
    "name": "sub_accounts",
    "view_permission":"1",
    "create_modify_permission":"1"
    }
  ]
  const AdminModules = [
    {
    "name": "payin",
    "view_permission":"1",
    "create_modify_permission":"1"
    },
    {
    "name": "payout",
    "view_permission":"1",
    "create_modify_permission":"1"
    },
    {
    "name": "settlements",
    "view_permission":"1",
    "create_modify_permission":"1"
    },
    {
    "name": "withdrawal",
    "view_permission":"1",
    "create_modify_permission":"1"
    },
    {
    "name": "top_up",
    "view_permission":"1",
    "create_modify_permission":"1"
    },
    {
    "name": "account_management",
    "view_permission":"1",
    "create_modify_permission":"1"
    },
    {
    "name": "reports",
    "view_permission":"1",
    "create_modify_permission":"1"
    },
    {
    "name": "technical",
    "view_permission":"1",
    "create_modify_permission":"1"
    },
    {
    "name": "sub_accounts",
    "view_permission":"1",
    "create_modify_permission":"1"
    },
    {
    "name": "administrator",
    "view_permission":"1",
    "create_modify_permission":"1"
    }
  ]

  const ResellerModules = [
    // {
    // "name": "payin",
    // "view_permission":"1",
    // "create_modify_permission":"1"
    // },
    // {
    // "name": "payout",
    // "view_permission":"1",
    // "create_modify_permission":"1"
    // },
    // {
    // "name": "settlements",
    // "view_permission":"1",
    // "create_modify_permission":"1"
    // },
    {
    "name": "withdrawal",
    "view_permission":"1",
    "create_modify_permission":"1"
    },
    {
    "name": "account_management",
    "view_permission":"1",
    "create_modify_permission":"1"
    },
    {
    "name": "reports",
    "view_permission":"1",
    "create_modify_permission":"1"
    },
    // {
    // "name": "technical",
    // "view_permission":"1",
    // "create_modify_permission":"1"
    // },
    {
    "name": "sub_accounts",
    "view_permission":"1",
    "create_modify_permission":"1"
    }
  ]
  const ResellerDefault =
  {
    "account_db_code": "-1",
    "account_name": "Current Account",
    "account_type": "",
    "up_reseller_count": 0,
    "reseller_parent": [],
    "reseller_level": "",
    "reseller_code": "",
    "merchant_count": 0
  }
  export default {
    components: {
      Common,
    },
    props: {
      data: {
        type: Object,
        required: true,
      }
    },
    data() {
      return {
        updateData: {
          preview_username:""
        },
        accessToken: '',
        accessEmail: '',
        accessPhone: '',
        accessUsername: '',
        account_type: '',
        loading: false,
        modalSubmit: false,
        modalLoading: false,
        passwordFieldType_current: "password",
        classicon_current: "uil uil-eye-slash",
        passwordFieldType: "password",
        classicon: "uil uil-eye-slash",
        passwordFieldType2: "password",
        classicon2: "uil uil-eye-slash",
        returnData: [],
        tabs_index:0,
        selectedReseller: ResellerDefault,
        reseller:[ResellerDefault],
        formData: {
          username:"",
          password: "",
          confirmPassword: "",
          name:"",
          permissions:[],
        },
        formEditData: {
          id:"",
          username:"",
          name:"",
          status:"",
          login_block:"",
          permissions:[],
        },
        resetPassword: {
          id:"",
          password: "",
          confirmPassword: ""
        },
        currentModules:[]
      };
    },
    validations: {
      formData: {
        username: {
          required,
          validUsername
        },
        name: {
          required,
        },
        password: {
          required,
          alpha,
          minLength: minLength(8),
        },
        confirmPassword: {
          required,
          sameAsPassword: sameAs("password"),
        },
        permissions: {
          required,
          minLength: minLength(1),
        }
      },
      formEditData: {
        name: {
          required,
        },
        permissions: {
          required,
          minLength: minLength(1),
        }
      },
      resetPassword: {
        password: {
          required,
          alpha,
          minLength: minLength(8),
        },
        confirmPassword: {
          required,
          sameAsPassword: sameAs("password"),
        }
      }

    },
    middleware: "authentication",
    async mounted() {
      this.reload();
    },
    created() {
      
    },
    methods: {
      moduleChecked(value){
       if(value.view_permission==0){
        value.create_modify_permission=0}
        else{
          value.create_modify_permission=1
        }
      },
      switchVisibility() {
        (this.classicon =
          this.classicon === "uil uil-eye-slash"
            ? "uil uil-eye"
            : "uil uil-eye-slash"),
          (this.passwordFieldType =
            this.passwordFieldType === "password" ? "text" : "password");
      },
      switchVisibility2() {
        (this.classicon2 =
          this.classicon2 === "uil uil-eye-slash"
            ? "uil uil-eye"
            : "uil uil-eye-slash"),
          (this.passwordFieldType2 =
            this.passwordFieldType2 === "password" ? "text" : "password");
      },
      updateAllInput(){
        if (this.quickUpdate.all_value!==''){
          this.quickUpdate={
              all_value:this.quickUpdate.all_value,
              payin_rate: this.quickUpdate.all_value,
              payin_extra_charge: this.quickUpdate.all_value,
              payout_rate: this.quickUpdate.all_value,
              payout_extra_charge: this.quickUpdate.all_value,
              settlement_rate: this.quickUpdate.all_value,
              settlement_extra_charge: this.quickUpdate.all_value,
              ewallet_rate: this.quickUpdate.all_value,
              ewallet_extra_charge: this.quickUpdate.all_value,
          }
        }
      },
    
      inputNumberOnly(event, parentModel, childModel) {
        const numericValue = event.target.value.replace(/[^0-9.]/g, '');
        this[parentModel][childModel] = numericValue
      },
      inputNumberOnlyV2(event, parentModel, childModel) {
        var numericValue = event.target.value.replace(/[^0-9.]/g, '');
        this[parentModel][childModel] = numericValue
        if(numericValue.length > 2){
          var val = numericValue.replace(/[^0-9]/g, '');
          console.log(val);
          val = val.substr(0, val.length-2)+"."+val.substr(-2);
          this[parentModel][childModel] = val;
        }
      },
      inputNumberOnlyV3(event, parentModel, childModel, index) {
        var numericValue = event.target.value.replace(/[^0-9.]/g, '');
        //this[parentModel][childModel] = numericValue
        this[parentModel].contract_selected[index][childModel] = numericValue
        if(numericValue.length > 2){
          var val = numericValue.replace(/[^0-9]/g, '');
          console.log(val);
          val = val.substr(0, val.length-2)+"."+val.substr(-2);
          this[parentModel].contract_selected[index][childModel] = val;
        }
      },
      convertCurrencyFormat(value, show00) {
        if (show00 == true) {
          return this.$refs.commonFunc.convertCurrencyFormat(value)
        } else {
          // Assuming this.transactionLimit is defined in your component's data
          return this.$refs.commonFunc.convertCurrencyFormat(value).replaceAll(".00", "");
        }
      },
      showModal() {
        this.$refs['modal-create-user'].show()
        this.accessToken = this.data.assessToken;
        this.accessUsername = this.data.accessUsername;
        this.updateData = this.data.updateData
        if (this.updateData.sub_account){
          this.updateData.preview_username = (this.updateData.account_type =='admin') ? this.formData.username: `${this.updateData.account_username.split('@')[1]}`
        }else{
          this.updateData.preview_username = (this.updateData.account_type =='admin') ? this.formData.username: `${this.updateData.account_username}`
        }
        console.log(this.updateData.account_db_code,this.updateData.account_type)
        if (this.updateData.account_db_code==''){
          this.currentModules = AdminModules;
        }else if (this.account_type=='reseller'){
          this.currentModules = ResellerModules;
        }else if (this.updateData.account_type=='reseller'){
          this.currentModules = ResellerModules;
        }else if (this.account_type=='merchant'){
          this.currentModules = MerchantModules;
        }else if (this.updateData.account_type=='merchant'){
          this.currentModules = MerchantModules;
        }
        
        this.formData.permissions = this.currentModules;
        //this.getReseller('create');
      },
      showEditModal() {
        this.$refs['modal-edit-user'].show()
        this.accessToken = this.data.assessToken;
        this.accessUsername = this.data.accessUsername;
        this.updateData = this.data.updateData;
        this.formEditData =  {
          id: this.data.updateData.account_db_code,
          username: this.data.updateData.username,
          name: this.data.updateData.name,
          status: this.data.updateData.status,
          login_block: this.data.updateData.login_block,
          permissions: this.data.updateData.modules
        }
        this.resetPassword =  {
          id:this.data.updateData.account_db_code,
          password: "",
          confirmPassword: ""
        }
      },
      showResetPasswordModal() {
        this.$refs['modal-reset-password'].show()
      },
      showModules() {
        this.$refs['modal-modules'].show()
        this.accessToken = this.data.assessToken;
        this.accessUsername = this.data.accessUsername;
        this.updateData = this.data.updateData
      },
      resetModal() {
        this.modalSubmit = false;
        this.modalLoading = false;
        this.formData= {
          username:"",
          password: "",
          confirmPassword: "",
          name:"",
          permissions:[],
        },
        this.formEditData =  {
          id:"",
          username:"",
          name:"",
          status:"",
          login_block:"",
          permissions:[],
        }
        this.resetPassword =  {
          id:"",
          password: "",
          confirmPassword: ""
        }
      },
      resetModal2() {
        this.modalSubmit = false;
        this.modalLoading = false;
        this.resetPassword.password= ""
        this.resetPassword.confirmPassword= ""
      },
      addUserRequest(){
        this.modalSubmit = true
        this.$v.formData.$touch();
        console.log((this.$v.formData.$invalid))
        var NoModuleEnabled = this.formData.permissions.map((item) => parseInt(item.view_permission)).includes(1);
        if (this.$v.formData.$invalid) {
          return;
        }
        else if (!NoModuleEnabled) {
          Swal.fire(
            {
            icon: 'error',
            title: 'Oops...',
            html: `Please enable at least one permissions.`,
            confirmButtonColor: '#222',
            confirmButtonText: this.$t('siteLang.Done'),
          })
        }
        else {
          this.$Progress.start();
          this.modalLoading = true;
          var bodyFormData = new FormData();
          var username =  ''
          if (this.updateData.sub_account){
            username =  (this.updateData.account_type =='admin') ? this.formData.username: `${this.formData.username}:${this.updateData.account_username.split('@')[1]}`
          }else{
            username = (this.updateData.account_type =='admin') ? this.formData.username: `${this.formData.username}@${this.updateData.account_username}`
          }
          bodyFormData.append("accessToken", this.accessToken);
          bodyFormData.append("accessUsername", this.accessUsername);
          bodyFormData.append("name", this.formData.name);
          bodyFormData.append("username", username);
          bodyFormData.append("password", this.formData.password);
          bodyFormData.append("permissions", JSON.stringify(this.formData.permissions));
          axios({
              method: "post",
              url:   (this.updateData.account_type =='admin') ? `${appConfig.APIHostAdmin}controller/admin/addAdmin`: `${appConfig.APIHostAdmin}controller/admin/addSubAccount`,
              data: bodyFormData,
              headers: { "Content-Type": "multipart/form-data" },
          })
          .then((response)=>{
              
              var resData = response.data;
              if (resData.status == 200) {
                console.log(resData)
                Swal.fire({
                  icon: 'success',
                  title: 'User Account Created',
                  html: 'Your user account has been successfully created!',
                  confirmButtonColor: '#FA6541',
                  confirmButtonText: this.$t('siteLang.Done')
                }).then(() => {
                  this.$refs['modal-create-user'].hide()
                  this.$emit('callParentFunction');
                })
                
              } 
              else if (resData.status == 440){
                Swal.fire(
                    {
                    icon: 'error',
                    title: 'Oops...',
                    html: `${resData.message}.`,
                    confirmButtonColor: '#222',
                    confirmButtonText: this.$t('siteLang.Done'),
                  })
                  localStorage.clear();
                  this.$router.push({
                      path: "/login",
                });
              }
              else {
                  Swal.fire(
                    {
                    icon: 'error',
                    title: 'Oops...',
                    html: `${resData.message}.`,
                    confirmButtonColor: '#222',
                    confirmButtonText: this.$t('siteLang.Done'),
                  })
              }
              this.modalLoading = false;
              this.modalSubmit = false
              this.$Progress.finish();
            })
            .catch((error)=> {
                this.modalLoading = false;
                this.modalSubmit = false
                this.$Progress.finish();
                //Swal.fire("Error", error, "error");
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${error}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
          });
        }
      },
      resetPasswordRequest(){
        this.modalSubmit = true
        this.$v.resetPassword.$touch();
        console.log((this.$v.resetPassword.$invalid))

        if (this.$v.resetPassword.$invalid) {
          return;
        }
        else {
          this.$Progress.start();
          this.modalLoading = true;
          var bodyFormData = new FormData();
          
          bodyFormData.append("accessToken", this.accessToken);
          bodyFormData.append("accessUsername", this.accessUsername);
          bodyFormData.append("accountDbCode", this.resetPassword.id);
          bodyFormData.append("password", this.resetPassword.password);
          axios({
              method: "post",
              // url:   (this.updateData.account_type =='admin') ? `${appConfig.APIHostAdmin}controller/admin/resetAdminPassword`: `${appConfig.APIHostAdmin}controller/admin/resetResellerMerchantPassword`,
              url:   (this.updateData.account_type =='admin') ? `${appConfig.APIHostAdmin}controller/admin/resetAdminPassword`: `${appConfig.APIHostAdmin}controller/admin/resetSubResellerMerchantPassword`,
              data: bodyFormData,
              headers: { "Content-Type": "multipart/form-data" },
          })
          .then((response)=>{
              
              var resData = response.data;
              if (resData.status == 200) {
                console.log(resData)
                Swal.fire({
                  icon: 'success',
                  title: 'Password Reset Success',
                  html: 'Your password for this account has been successfully reset!',
                  confirmButtonColor: '#FA6541',
                  confirmButtonText: this.$t('siteLang.Done')
                }).then(() => {
                  this.$refs['modal-reset-password'].hide()
                  this.$refs['modal-edit-user'].hide()
                  this.$emit('callParentFunction');
                })
                
              } 
              else if (resData.status == 440){
                Swal.fire(
                    {
                    icon: 'error',
                    title: 'Oops...',
                    html: `${resData.message}.`,
                    confirmButtonColor: '#222',
                    confirmButtonText: this.$t('siteLang.Done'),
                  })
                  localStorage.clear();
                  this.$router.push({
                      path: "/login",
                });
              }
              else {
                  Swal.fire(
                    {
                    icon: 'error',
                    title: 'Oops...',
                    html: `${resData.message}.`,
                    confirmButtonColor: '#222',
                    confirmButtonText: this.$t('siteLang.Done'),
                  })
              }
              this.modalLoading = false;
              this.modalSubmit = false
              this.$Progress.finish();
            })
            .catch((error)=> {
                this.modalLoading = false;
                this.modalSubmit = false
                this.$Progress.finish();
                //Swal.fire("Error", error, "error");
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${error}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
          });
        }
      },
      updateUserRequest(){
        this.modalSubmit = true
        this.$v.formEditData.$touch();
        console.log((this.$v.formEditData.$invalid))
        var NoModuleEnabled = this.formEditData.permissions.map((item) => parseInt(item.view_permission)).includes(1);
        if (this.$v.formEditData.$invalid) {
          return;
        }
        else if (!NoModuleEnabled) {
          Swal.fire(
            {
            icon: 'error',
            title: 'Oops...',
            html: `Please enable at least one permissions.`,
            confirmButtonColor: '#222',
            confirmButtonText: this.$t('siteLang.Done'),
          })
        }else {
          this.$Progress.start();
          this.modalLoading = true;
          var bodyFormData = new FormData();
          bodyFormData.append("accessToken", this.accessToken);
          bodyFormData.append("accessUsername", this.accessUsername);
          bodyFormData.append("accountDbCode", this.formEditData.id);
          bodyFormData.append("name", this.formEditData.name);
          bodyFormData.append("status", this.formEditData.status);
          bodyFormData.append("loginBlock", this.formEditData.login_block);
          bodyFormData.append("permissions", JSON.stringify(this.formEditData.permissions));
          axios({
              method: "post",
              url:  (this.updateData.account_type =='admin') ? `${appConfig.APIHostAdmin}controller/admin/updateAdminAccount`: `${appConfig.APIHostAdmin}controller/admin/updateSubAccount`,
              data: bodyFormData,
              headers: { "Content-Type": "multipart/form-data" },
          })
          .then((response)=>{
              
              var resData = response.data;
              if (resData.status == 200) {
                console.log(resData)
                Swal.fire({
                  icon: 'success',
                  title: 'User Account Updated',
                  html: 'Your user account has been successfully updated!',
                  confirmButtonColor: '#FA6541',
                  confirmButtonText: this.$t('siteLang.Done')
                }).then(() => {
                  this.$refs['modal-edit-user'].hide()
                  this.$emit('callParentFunction');
                })
                
              } 
              else if (resData.status == 440){
                Swal.fire(
                    {
                    icon: 'error',
                    title: 'Oops...',
                    html: `${resData.message}.`,
                    confirmButtonColor: '#222',
                    confirmButtonText: this.$t('siteLang.Done'),
                  })
                  localStorage.clear();
                  this.$router.push({
                      path: "/login",
                });
              }
              else {
                  Swal.fire(
                    {
                    icon: 'error',
                    title: 'Oops...',
                    html: `${resData.message}.`,
                    confirmButtonColor: '#222',
                    confirmButtonText: this.$t('siteLang.Done'),
                  })
              }
              this.modalLoading = false;
              this.modalSubmit = false
              this.$Progress.finish();
            })
            .catch((error)=> {
                this.modalLoading = false;
                this.modalSubmit = false
                this.$Progress.finish();
                //Swal.fire("Error", error, "error");
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${error}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
          });
        }
      },
      async reload() {
        try {
          await this.$nextTick(); // Wait for the component to be fully mounted
          const commonFunc = this.$refs.commonFunc;
          if (commonFunc && commonFunc.getFullData) {
            const data = await commonFunc.getFullData();
            this.account_type = data.account_type;
            
          } else {
            // Handle the case when commonFunc or getFullData is undefined
            // Perform appropriate actions or show a fallback message to the user
          }
        } catch (error) {
          // Handle the error silently without propagating it further
        }
      },
    }
  };
</script>
<style scoped>
  label{
    margin-bottom: 5px;
  }
  #eye_current,
  #eye,
  #eye2,
  #eye3{
  position: absolute;
  top: 4px;
  padding: 5px 10px;
  right: 5px;
  text-align: center;
  width: 32px;
  color: #999;
  z-index: 3;
  background-color: #fff;
  cursor: pointer;
  }
  .custom-table td .row{
    min-width:220px;
    
  }
</style>